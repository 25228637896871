<template>
  <div class="about-contaner">
    <Header></Header>
    <Nav></Nav>
    <!-- 关于我 start -->
    <el-row class="about-box">
      <el-row class="contaner">
        <!-- 关于我们内容 start -->
        <el-row class="contact" type="flex">
          <!-- 左侧tab -->
          <el-row class="left">
            <ul>
              <li>
                <router-link :to="{ path: '/contact/personal' }">
                  <div class="line"></div>
                  <span>个人中心</span>
                </router-link>
              </li>
              <!-- <li>
                <router-link :to="{ path: '/contact/order' }">
                  <div class="line"></div>
                  <span>我的订单</span>
                </router-link>
              </li> -->
              <!-- <li>
                <router-link :to="{ path: '/contact/address' }">
                  <div class="line"></div>
                  <span>我的地址</span>
                </router-link>
              </li> -->
              <li>
                <router-link :to="{ path: '/contact/collect' }">
                  <div class="line"></div>
                  <span>我的收藏</span>
                </router-link>
              </li>
              <!-- <li>
                <router-link :to="{ path: '/contact/record' }">
                  <div class="line"></div>
                  <span>意向记录</span>
                </router-link>
              </li> -->
              <li>
                <router-link :to="{ path: '/contact/company' }">
                  <div class="line"></div>
                  <span>所属公司</span>
                </router-link>
              </li>
              <!-- <li>
                <router-link :to="{ path: '/contact/connect' }">
                  <div class="line"></div>
                  <span>联系我们</span>
                </router-link>
              </li> -->
              <!-- <li>
                <router-link :to="{ path: '/contact/info' }">
                  <div class="line"></div>
                  <span>系统消息</span>
                </router-link>
              </li> -->
              <!-- <li>
                <router-link :to="{ path: '/contact/workshoprun' }">
                  <div class="line"></div>
                  <span>车间管理</span>
                </router-link>
              </li> -->
              <!-- <li>
                <router-link :to="{ path: '/contact/servicelevel' }">
                  <div class="line"></div>
                  <span>保养标准</span>
                </router-link>
              </li> -->
              <!-- <li>
                <router-link :to="{ path: '/contact/serviceplan' }">
                  <div class="line"></div>
                  <span>保养计划</span>
                </router-link>
              </li> -->
              <!-- <li>
                <router-link :to="{ path: '/contact/info' }">
                  <div class="line"></div>
                  <span>点检标准</span>
                </router-link>
              </li>
              <li>
                <router-link :to="{ path: '/contact/info' }">
                  <div class="line"></div>
                  <span>点检计划</span>
                </router-link>
              </li> -->
              <li>
                <router-link :to="{ path: '/contact/set' }">
                  <div class="line"></div>
                  <span>系统设置</span>
                </router-link>
              </li>
            </ul>
          </el-row>
          <!-- 右侧内容 -->
          <div class="right">
            <router-view></router-view>
          </div>
        </el-row>
        <!-- 关于我们内容 end -->
      </el-row>
    </el-row>
    <Footer></Footer>
    <!-- 右侧保修 客服 -->
    <!-- <el-row class="fix-right">
      <el-row class="item2">
        <el-button type="text" @click="assderVisible = true">
          <img src="@/assets/images/home/kefu.png" alt="" />
          <p>联系客服</p>
        </el-button>
      </el-row>
    </el-row> -->
    <!-- 弹窗 start -->
    <!-- <el-dialog
      :visible.sync="assderVisible"
      width="600px"
      :show-close="false"
      class="assderTc"
    >
      <span slot="title" class="title">
        <p class="fs18 font-33">联系平台</p>
        <img
          src="@/assets/images/home/close.png"
          @click="assderVisible = false"
          class="hand"
        />
      </span>

      <div class="ja-c fs14 font-66 w100">
        <p>
          <img src="@/assets/images/home/phone.png" alt="" />
          <span>13712345678</span>
        </p>
        <p>
          <img src="@/assets/images/home/weixin.png" alt="" />
          <span>13712345678</span>
        </p>
        <p>
          <img src="@/assets/images/home/qq.png" alt="" />
          <span>13712345678</span>
        </p>
        <p>
          <img src="@/assets/images/home/email.png" alt="" />
          <span>13712345678</span>
        </p>
      </div>
    </el-dialog> -->
    <!-- 弹窗结束 -->
    <tell-us transform="translateY(-50%)" top="50%"></tell-us>
  </div>
</template>

<script>
import Header from "@/components/home/Header.vue";
import Nav from "@/components/home/Nav.vue";
import Footer from "@/components/home/Footer.vue";
import TellUs from "@/components/TellUs.vue";
export default {
  components: {
    Header,
    Nav,
    Footer,
    TellUs,
  },
  data() {
    return {
      assderVisible: false, //客服弹窗
      tabPosition: "left", //tab
    };
  },

  mounted() {},

  methods: {
    // 控制客服弹窗
    assderHandle() {
      this.assderVisible = false;
    },
  },
};
</script>

<style lang="scss">
.about-contaner {
  width: 100%;
  height: 100%;
  .about-box {
    min-width: 1200px;
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;

    background: #f8f8f8;
    .contaner {
      background: #f8f8f8;
    }
    .nav-box {
      .pos {
        color: #333333;
        font-size: 16px;
      }
    }
  }
}
// 右侧保修 客服
// .fix-right {
//   cursor: pointer;
//   width: 88px;
//   height: 88px;
//   border: 1px solid #ebebeb;
//   position: fixed;
//   z-index: 1000;
//   right: 0;
//   // bottom: 266px;
//   top: 50%;
//   transform: translateY(-50%);
//   .item1 {
//     background-color: #ffffff;
//   }
//   .item2 {
//     background-color: #ed1b23;
//     z-index: 999;
//   }
//   .item1,
//   .item2 {
//     height: 90px;
//     text-align: center;
//     box-sizing: border-box;
//     padding-top: 9px;
//     // padding-bottom: 16px;
//     img {
//       width: 24px;
//       height: 24px;
//     }
//     p {
//       color: #757575;
//       font-size: 14px;
//       margin-top: 12px;
//     }
//   }
//   .item2 p {
//     color: #ffffff;
//   }
// }
// 自定义弹窗样式
// .assderTc {
//   display: flex;
//   align-items: center;
//   .title {
//     padding-top: 32px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     position: relative;
//     font-size: 22px;
//     font-weight: bold;
//     color: #333333;
//     img {
//       width: 32px;
//       height: 32px;
//       position: absolute;
//       top: 32px;
//       right: 32px;
//     }
//   }
//   ::v-deep .el-dialog__header {
//     padding: 0;
//   }
//   ::v-deep .el-dialog {
//     border-radius: 0 !important;
//   }
//   ::v-deep .el-dialog__body {
//     padding: 0;
//   }
//   ::v-deep .el-dialog__footer {
//     padding: 0;
//   }
//   ::v-deep .el-dialog {
//     border-radius: 10px;
//   }
//   .ja-c {
//     height: 311px;
//     box-sizing: border-box;
//     padding-left: 214px;
//     padding-top: 22px;
//     p {
//       display: flex;
//       align-items: center;
//       margin-bottom: 32px;
//       img {
//         width: 30px;
//         height: 30px;
//       }
//       span {
//         font-size: 14px;
//         color: #333333;
//         margin-left: 16px;
//       }
//     }
//   }

//   .btnad {
//     width: 112px;
//     height: 40px;
//     background: rgba(246, 247, 249, 1);
//     border: 1px solid #ecedef;
//     opacity: 1;
//     border-radius: 20px;
//   }
//   .btnsad {
//     color: #333;
//     width: 112px;
//     height: 40px;
//     background: rgba(255, 195, 44, 1);
//     opacity: 1;
//     border-radius: 20px;
//   }
// }
// 内容区
.contact {
  .left {
    width: 200px;
    height: 850px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    margin-right: 24px;
    ul {
      li {
        height: 32px;
        margin-top: 26px;
        .line {
          width: 6px;
          height: 32px;
          background: #ff0015;
          display: inline-block;
          margin-right: 62px;
          opacity: 0;
        }
        span {
          position: relative;
          top: -10px;
          font-size: 14px;
          color: #666666;
        }
        .router-link-active {
          span {
            color: #ff0015 !important;
          }
          .line {
            opacity: 1;
          }
        }
      }
    }
  }
  .right {
    width: 976px;
    min-height: 700px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
  }
}
</style>
