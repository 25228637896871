<template>
  <div style="display: none;" >
    <!-- 右侧保修 客服 -->
    <el-row
      class="fix-right"
      :style="{ bottom: bottom, top: top, transform: transform }"
    >
      <el-row class="item2">
        <el-button type="text" @click="assderVisible = true">
          <img src="@/assets/images/home/kefu.png" alt="" />
          <p>联系客服</p>
        </el-button>
      </el-row>
    </el-row>
    <!-- 弹窗 start -->
    <el-dialog
      :visible.sync="assderVisible"
      width="600px"
      :show-close="false"
      class="assderTc"
    >
      <span slot="title" class="title">
        <p class="fs18 font-33">联系平台</p>
        <img
          src="@/assets/images/home/close.png"
          @click="assderVisible = false"
          class="hand"
        />
      </span>

      <div class="ja-c fs14 font-66 w100">
        <p>
          <img src="@/assets/images/home/phone.png" alt="" />
          <span>{{ showText.officialTel }}</span>
        </p>
        <p>
          <img src="@/assets/images/home/weixin.png" alt="" />
          <span>{{ showText.officialWeChat }}</span>
        </p>
        <p>
          <img src="@/assets/images/home/qq.png" alt="" />
          <span>{{ showText.officialQq }}</span>
        </p>
        <p>
          <img src="@/assets/images/home/email.png" alt="" />
          <span>{{ showText.officialMail }}</span>
        </p>
      </div>
    </el-dialog>
    <!-- 弹窗结束 -->
  </div>
</template>

<script>
export default {
  name: "YoufujichuangTellUs",
  props: ["bottom", "top", "transform"],

  data() {
    return {
      assderVisible: false, //客服弹窗
      showText: {},
    };
  },

  mounted() {},

  created() {
    //根据分组查询系统配置
    this.getConfig();
  },

  methods: {
    //根据分组查询系统配置
    getConfig() {
      let paramData = {
        configGroup: "tellUs",
      };
      this.$API
        .getConfig(paramData)
        .then((res) => {
          if (res.code == 1) {
            this.showText = res.data;
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 控制客服弹窗
    assderHandle() {
      this.assderVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
// 右侧保修 客服
// a {
//   color: blueviolet;
// }
.fix-right {
  cursor: pointer;
  width: 88px;
  height: 90px;
  border: 1px solid #ebebeb;
  position: fixed;
  z-index: 1000;
  right: 0;
  //   bottom: 266px;
  // top: 50%;
  // transform: translateY(-50%);
  .el-dialog__body {
    padding: 0px;
  }
  .item1 {
    background-color: #ffffff;
  }
  .item2 {
    background-color: #ed1b23;
    z-index: 999;
  }
  .item1,
  .item2 {
    height: 90px;
    text-align: center;
    box-sizing: border-box;
    padding-top: 9px;
    // padding-bottom: 16px;
    img {
      width: 24px;
      height: 24px;
    }
    p {
      color: #757575;
      font-size: 14px;
      margin-top: 12px;
    }
  }
  .item2 p {
    color: #ffffff;
  }
}
// 自定义弹窗样式
.assderTc {
  display: flex;
  align-items: center;
  .title {
    padding-top: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: 22px;
    font-weight: bold;
    color: #333333;
    img {
      width: 32px;
      height: 32px;
      position: absolute;
      top: 32px;
      right: 32px;
    }
  }
  ::v-deep .el-dialog__header {
    padding: 0;
  }
  ::v-deep .el-dialog {
    border-radius: 0 !important;
  }
  ::v-deep .el-dialog__body {
    padding: 0;
  }
  ::v-deep .el-dialog__footer {
    padding: 0;
  }
  ::v-deep .el-dialog {
    border-radius: 10px;
  }
  .ja-c {
    height: 311px;
    box-sizing: border-box;
    padding-left: 214px;
    padding-top: 52px;
    p {
      display: flex;
      align-items: center;
      margin-bottom: 32px;
      img {
        width: 30px;
        height: 30px;
      }
      span {
        font-size: 14px;
        color: #333333;
        margin-left: 16px;
      }
    }
  }

  .btnad {
    width: 112px;
    height: 40px;
    background: rgba(246, 247, 249, 1);
    border: 1px solid #ecedef;
    opacity: 1;
    border-radius: 20px;
  }
  .btnsad {
    color: #333;
    width: 112px;
    height: 40px;
    background: rgba(255, 195, 44, 1);
    opacity: 1;
    border-radius: 20px;
  }
}
</style>